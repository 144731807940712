import React, { useRef, useState, Fragment, useContext } from "react"
import { navigate } from "gatsby"

import MobileSearch from "../Search/MobileSearch"
import AddMedicineSection from "./SearchMedsMobile/AddMedicineSection"

import { AppContext } from "../../context/AppContext"
import { isMedicineAlreadyInCart } from "./services/cart"
import { useEpharmacyProducts } from "./hooks/useEpharmacyProducts"

const SearchMedsMobile = () => {
  const [selectedMedicine, setSelectedMedicine] = useState(null)
  const { state, dispatch } = useContext(AppContext)
  const inputRef = useRef({ current: { value: "" } })
  const data = useEpharmacyProducts()

  const proceedToSearch = () => {
    navigate("/epharmacy/order")
  }

  const proceedToCheckout = () => {
    navigate("/epharmacy/checkout")
  }

  const handlePartnerRequest = () => {
    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, hasPartnerRequest: true },
      },
    })
  }

  const handleOnSelect = (medicine) => {
    if (medicine.productTitle === "Partner Request (MedGrocer)")
      return handlePartnerRequest()
    if (isMedicineAlreadyInCart(medicine, state.epharmacy.medicines))
      dispatch({
        type: "SHOW_TOAST",
        payload: {
          message: `${medicine.productTitle} already in cart`,
          color: "danger",
        },
      })
    else setSelectedMedicine(medicine)
  }

  const searchComponentProps = {
    placeholder: "Atorvastatin or Lipitor",
    label: "Medicine Search",
    dataSource: {
      type: "graphql",
      data: data,
      lookupKeys: ["molecule", "brand"],
    },
    onSelect: handleOnSelect,
    inputRef: inputRef,
    backRoute: "/epharmacy/order",
  }

  const addMedicineToCart = async (
    item,
    qty,
    indication,
    dailyIntake,
    others
  ) => {
    const medicineList = [...state.epharmacy.medicines]

    if (!item.molecule)
      medicineList.push({
        ...item,
        qty: qty,
        indication: indication,
        dailyIntake: dailyIntake,
        others: others,
      })
    else
      medicineList.push({
        ...item,
        qty: qty,
        indication: indication,
        dailyIntake: dailyIntake,
        others: others,
      })
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, medicines: medicineList },
      },
    })
    navigate("/epharmacy/order", {
      state: {
        selectedMedicine: item.molecule,
      },
    })
  }

  return (
    <Fragment>
      <MobileSearch {...searchComponentProps} />
      {selectedMedicine?.productTitle && (
        <AddMedicineSection
          addMedicineToCart={addMedicineToCart}
          setSelectedMedicine={setSelectedMedicine}
          selectedMedicine={selectedMedicine}
        />
      )}
    </Fragment>
  )
}

export default SearchMedsMobile
