import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import RxIndicator from "../../Elements/RxIndicator"

import { generatePrice } from "../../Epharmacy/services/computations"
import styles from "../utils/search.module.scss"

const MobileSearchResults = ({
  searchResults,
  isExactMatch,
  onSelect,
  searchDispatch,
  searchQuery,
}) => {
  const sortedSearchResults = searchResults.sort((a, b) => {
    return a.closestMatch === "molecule" &&
      a.productTitle.includes("Value Brand") &&
      a.distance >= b.distance
      ? -1
      : 1
  })

  let hasSomeMatch = false

  let queryLowerCase = searchQuery.toLowerCase()

  // Check each productTitle for inclusion of searchQuery
  for (const result of searchResults) {
    const productTitleLowerCase = result.productTitle.toLowerCase()
    if (productTitleLowerCase.includes(queryLowerCase)) {
      // If found, return true
      hasSomeMatch = true
    }
  }

  return (
    <section className={classNames(styles["mobile_search__results"])}>
      <ol className={classNames({ "is-hidden": !searchResults.length })}>
        {searchResults.length > 0 && !hasSomeMatch && (
          <li className={classNames(styles["errorMessage"], "is-size-7")}>
            <FontAwesomeIcon icon={faExclamationCircle} />{" "}
            <b>Cannot find item that matches the search description.</b> Please
            type the drug again or check if any of the items below match what
            you're looking for.
          </li>
        )}
        {sortedSearchResults.map((result) => (
          <li
            onClick={() => {
              if (onSelect) onSelect(result)
              searchDispatch({ type: "SET_SEARCH_QUERY", payload: "" })
              searchDispatch({ type: "SET_SEARCH_RESULTS", payload: [] })
            }}
          >
            <div className="is-flex has-text-weight-bold">
              <div>{result.productTitle}</div>
              {result.rxRequired && <RxIndicator />}
            </div>
            <div className="is-size-7">
              {result?.price_list?.context || ` Php ${generatePrice(result)}`}
            </div>
          </li>
        ))}
      </ol>
    </section>
  )
}

export default MobileSearchResults
